import { RiDoorLine } from 'react-icons/ri'
import { primary_color } from '../../../Helper/uiHelper'
import { useRef, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const checkRowCanBeRemoved = (list, index) => {
  const lockers = list[index]
  let action = true
  lockers?.map((item) => {
    if (item?.booked || item?.is_maintanance || item?.is_maintenance) {
      action = false
    }
  })
  return action
}

const checkColumnCanBeRemoved = (list, index) => {
  let action = true
  list?.map((row) => {
    row?.map((item, i) => {
      if (i === index && (item?.booked || item?.is_maintanance || item?.is_maintenance)) {
        action = false
      }
    })
  })
  return action
}
const LockerListing = ({
  lockers,
  selectedLockerIds,
  setSelectedLockerIds,
  isLockerSelectable,
  setSelectedLockers,
  selectedLockers,
  isSelectSingleLocker,
  isDraggable,
  archievedLocker,
  setArchievedLocker,
  setLockers,
  onDeleteEmptyLocker,
  isPlannedBlock,
  onAddColumnOrRow,
  onRemoveColumnOrRow,
  hideAddRemoveColumn,
}) => {
  const [isModelOpen, setIsModelOpen] = useState(false)
  const rowColumnActionPayload = useRef()

  

  const handleDragStart = (event, id) => {
    event.dataTransfer.setData('blockId', id)
    // const element = document.getElementById('archieve_area')
    // element.scrollIntoView()
  }

  const getColor = (item) => {
    if (item?.booked) {
      return '#06880BCC'
    } else if (item?.archieved) {
      return '#8E8E8E'
    } else if (item?.is_maintanance || item.is_maintenance) {
      return '#F87171'
    } else {
      return primary_color
    }
  }
  const allowDrop = (event) => {
    event.preventDefault()
  }
  const handleDrop = (event, i, index) => {
    event.preventDefault()
    const blockId = parseInt(event.dataTransfer.getData('archievedBlockId'), 10)
    if (!blockId) return
    let item
    let newArchievedLockers = []
    archievedLocker?.map((locker) => {
      if (locker?.id === blockId) {
        item = locker
      } else {
        newArchievedLockers?.push(locker)
      }
    })
    setArchievedLocker(newArchievedLockers)
    const newLockers = lockers?.map((row, rowIndex) => {
      if (rowIndex === i) {
        return row?.map((col, colInd) => {
          if (colInd === index) {
            return {
              ...col,
              ...item,
              archieved: false,
            }
          } else {
            return col
          }
        })
      } else {
        return row
      }
    })
    setLockers(newLockers)
  }

  return (
    <div className=' mb-2 !w-100 custom-scrollbar' style={{ overflow: 'auto' }}>
      <hr className='my-4'></hr>
      <div className='d-flex align-items-center mb-5 mt-5 subtitle1' >
        {/* <div className='rounded-pill mr-2 subtitle1' style={{ height: '1rem', width: '1rem', background: '#059669CC' }}></div> Currently Booked */}
        <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: primary_color }}></div> Active
        <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: '#06880BCC' }}></div> Booked
        <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: '#F87171' }}></div> Maintanance
      </div>
      <hr className='my-5'></hr>
      <div style={{ width: 'fit-content' }} className=''>
        {!hideAddRemoveColumn &&
          lockers?.slice(0, 1)?.map((locakerItem, i) => {
            return (
              <div className='d-flex'>
                <div
                  key={'locker-action00000'}
                  className='position-relative align-items-top justify-content-center'
                  style={{
                    width: '4rem',
                    margin: '0.1rem',
                    height: '3rem',
                  }}></div>
                {locakerItem?.map((item, index) => {
                  return (
                    <div
                      key={'locker-action' + index + i}
                      className='position-relative d-flex align-items-top justify-content-center'
                      style={{
                        width: '8rem',
                        margin: '0.1rem',
                        height: '3rem',
                      }}>
                      {checkColumnCanBeRemoved(lockers, index) && locakerItem?.length > 1 && (
                        <div
                          className='mr-2 px-2 py-1 cursor-pointer'
                          style={{ background: '#F87171', borderRadius: '5px', height: 'fit-content' }}
                          onClick={() => {
                            let item_ids = []
                            lockers?.map((row) => {
                              row?.map((item, i) => {
                                if (i === index) {
                                  item_ids?.push(item?.id)
                                }
                              })
                            })
                            onRemoveColumnOrRow({ item_ids, row: false }, 'Lockers removed successfully!')
                            // rowColumnActionPayload.current = {
                            //   payload: { item_ids, row: false },
                            //   message: 'Are you sure you want to delete column?',
                            //   type: 'remove',
                            // }
                            // setIsModelOpen(!isModelOpen)
                          }}>
                          <i class='fa-solid fa-minus text-white font-weight-bold'></i>
                        </div>
                      )}
                      <div
                        className='px-2 py-1 cursor-pointer'
                        style={{ background: primary_color, borderRadius: '5px', height: 'fit-content' }}
                        onClick={() => {
                          let item_id = lockers[lockers?.length - 1][index]?.id
                          // rowColumnActionPayload.current = {
                          //   payload: { item_id, count: lockers?.length, row: false },
                          //   message: 'Are you sure you want to add column?',
                          //   type: 'add',
                          // }
                          onAddColumnOrRow({ item_id, count: lockers?.length, row: false }, 'Lockers created successfully!')
                          // setIsModelOpen(!isModelOpen)
                        }}>
                        <i class='fa-solid fa-plus text-white font-weight-bold'></i>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        {lockers?.map((locakerItem, i) => {
          return (
            <div className='d-flex'>
              {locakerItem?.map((item, index) => {
                return (
                  <>
                    {!hideAddRemoveColumn && index === 0 && (
                      <div
                        key={'locker-action' + index + i}
                        className='position-relative d-flex flex-column align-items-top justify-content-center'
                        style={{
                          width: '4rem',
                          margin: '0.1rem',
                          height: '10rem',
                        }}>
                        {checkRowCanBeRemoved(lockers, i) && lockers?.length > 1 && (
                          <div
                            className='mb-2 px-2 py-1 cursor-pointer'
                            style={{ background: '#F87171', borderRadius: '5px', width: 'fit-content' }}
                            onClick={() => {
                              let item_ids = []
                              lockers?.[i]?.map((item, i) => {
                                item_ids?.push(item?.id)
                              })
                              onRemoveColumnOrRow({ item_ids, row: true }, 'Lockers removed successfully!')
                              // rowColumnActionPayload.current = {
                              //   payload: { item_ids, row: true, tier: i + 1 },
                              //   message: 'Are you sure you want to delete row?',
                              //   type: 'remove',
                              // }
                              // setIsModelOpen(!isModelOpen)
                            }}>
                            <i class='fa-solid fa-minus text-white font-weight-bold'></i>
                          </div>
                        )}
                        <div
                          className='px-2 py-1 cursor-pointer'
                          style={{ background: primary_color, borderRadius: '5px', width: 'fit-content' }}
                          onClick={() => {
                            let item_id = lockers[i][0]?.id
                            onAddColumnOrRow({ item_id, count: lockers[i]?.length, row: true, tier: i + 2 }, 'Lockers created successfully!')
                            // rowColumnActionPayload.current = {
                            //   payload: { item_id, count: lockers[i]?.length, row: true, tier: i + 2 },
                            //   message: 'Are you sure you want to add row?',
                            //   type: 'add',
                            // }
                            // setIsModelOpen(!isModelOpen)
                          }}>
                          <i class='fa-solid fa-plus text-white font-weight-bold'></i>
                        </div>
                      </div>
                    )}
                    {
                      <div
                        key={'locker' + index + i}
                        onClick={() => {
                          if (!isLockerSelectable) return
                          if (isSelectSingleLocker) {
                            setSelectedLockerIds([item?.id])
                            setSelectedLockers(JSON.parse(JSON.stringify([item])))
                            return
                          }
                          if (selectedLockerIds?.includes(item?.id)) {
                            const newIds = [...selectedLockerIds]
                            let itemIndex = newIds?.indexOf(item?.id)
                            newIds?.splice(itemIndex, 1)
                            setSelectedLockerIds(newIds)

                            const newLockers = [...selectedLockers]
                            newLockers?.splice(itemIndex, 1)
                            setSelectedLockers(JSON.parse(JSON.stringify(newLockers)))
                          } else {
                            const newIds = [...selectedLockerIds]
                            newIds?.push(item?.id)
                            setSelectedLockerIds(newIds)

                            const newLockers = [...selectedLockers]
                            newLockers?.push(item)
                            setSelectedLockers(JSON.parse(JSON.stringify(newLockers)))
                          }

                          // onChangeValue(item?.reserved, item?.id, item)
                        }}
                        className='position-relative'
                        data-id={item?.id}
                        draggable={isDraggable && !!item?.display}
                        onDragStart={(event) => handleDragStart(event, item.id)}
                        onDrop={(e) => handleDrop(e, i, index)}
                        onDragOver={allowDrop}
                        style={{
                          width: '8rem',
                          margin: '0.1rem',
                          height: '10rem',
                          background: !item?.display ? 'transparent' : getColor(item),
                          cursor: isLockerSelectable ? 'pointer' : 'default',
                          border: selectedLockerIds?.includes(item?.id) && `2px solid #001AFF`,
                        }}>
                        {!item?.display && (
                          <div
                            className='cursor-pointer position-absolute subtitle1'
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              onDeleteEmptyLocker(item?.id)
                            }}
                            style={{
                              right: '-1rem',
                              top: '-1rem',
                              background: 'rgb(248, 113, 113)',
                              padding: '0rem 0.5rem',
                              borderRadius: '14px',
                              fontWeight: '500',
                              zIndex: '10000',
                            }}>
                            <i class='fa-solid fa-xmark text-white'></i>
                          </div>
                        )}

                        {/* {!isDisplayZero ? (
                  <div
                    className='m-1 position-absolute subtitle1'
                    style={{
                      left: '0.5rem',
                      top: '0.25rem',
                      background: 'white',
                      padding: '0rem 0.5rem',
                      borderRadius: '14px',
                      fontWeight: '500',
                    }}>
                    <div>£{item?.price}</div>
                  </div>
                ) : null} */}

                        <div className='position-absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50% , -50%)' }}>
                          {/* <i class='fa-thin fa-door-closed' style={{ fontSize: '5rem' }}></i> */}
                          {!item?.archieved && !!item?.display && <RiDoorLine style={{ color: '#fff', fontSize: '6rem' }} />}
                        </div>
                        {!!item?.display && (
                          <div
                            className='text-align-center mt-2 subtitle1'
                            style={{
                              color: '#fff',
                              position: 'absolute',
                              bottom: '1rem',
                              fontWeight: '500',
                              left: '50%',
                              transform: 'translate(-50%)',
                            }}>
                            {item?.name}
                          </div>
                        )}
                      </div>
                    }
                  </>
                )
              })}
            </div>
          )
        })}
      </div>

      <Modal
        isOpen={isModelOpen}
        centered={true}
        toggle={() => {
          rowColumnActionPayload.current = null
          setIsModelOpen(!isModelOpen)
        }}
        style={{ width: '50rem', boxShadow: 'none', maxWidth: '50rem' }}>
        <ModalHeader style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem' }}>
          <div className='p font-weight-bold' style={{ color: primary_color }}>
            {rowColumnActionPayload?.current?.message}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='d-flex justify-content-between'>
            <button
              className='btn text-white rounded-pill mt-2'
              type='button'
              onClick={() => {
                let payload = JSON.parse(JSON.stringify(rowColumnActionPayload.current.payload))
                if (rowColumnActionPayload.current.type === 'add') {
                  onAddColumnOrRow(payload, 'Lockers created successfully!')
                } else {
                  onRemoveColumnOrRow(payload, 'Lockers removed successfully!')
                }
                rowColumnActionPayload.current = null
                setIsModelOpen(!isModelOpen)
              }}
              style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
              Yes
            </button>
            <button
              className='btn rounded-pill mt-2 text-danger border-danger'
              type='button'
              onClick={() => {
                rowColumnActionPayload.current = null
                setIsModelOpen(!isModelOpen)
              }}
              style={{ fontWeight: 700, width: '48%' }}>
              Cancel
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default LockerListing
