import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'

import Dashboard from '../Components/PageSpecificComponents/Dashboard/dashboard'
import Loader from '../Components/loader'
import Wrapper from '../Components/wrapper'
import { can, getNavFilterList } from '../helper'
import ErrorPage from '../Pages/ErrorPage'
import { setDomain, updatePageDataRedux } from '../Redux/Reducers/CMS'
import { request } from '../Redux/Sagas/requests/api'
import { authLogout } from '../Redux/Sagas/rootSaga'
import CreatePageRoute from './EditCMS/Page/pageWithGrid'
import PageWithTabs from './PageVariation/pageWithTabs'
import PageWithTabsNear from './pageWithNearTabs'

const select = (state) => ({
  editMode: state.CMS.editMode,
  navMenu: state.Navigations.InnerSidebarNav,
  pages: state.CMS.coreData.page,
  cmsData: state.cmsData,
  coreData: state.CMS.coreData,
  loading: state.Loader.loader,
  pageCrashed: state.CMS.pageCrashed,
})

const CMSPage = ({ location, navMenu, pages, cmsData, coreData, loading, pageCrashed }) => {
  const [page, updatePage] = useState({})
  const [pageName, updatePageName] = useState('')
  const [loadingPage, setLoadingPage] = useState(false)
  const [pageRequested, setPageRequested] = useState(false)
  const [pageRequestedAfterCMSRefresh, setPageRequestedAfterCMSRefresh] = useState(false)
  const [currentNav, setCurrentNav] = useState()
  const history = useHistory()

  const domain_id = useSelector((state) => state.CMS.selected_domain_id)

  const dispatch = useDispatch()

  useEffect(() => {
    if (pageCrashed) {
      dispatch(authLogout())
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (pageRequestedAfterCMSRefresh) {
        setPageRequestedAfterCMSRefresh(false)
      }
    }, 5000)
  }, [pageRequestedAfterCMSRefresh])

  useEffect(() => {
    const nav = getNavFilterList(navMenu)
    nav.map((item) => {
      if (location.pathname.replace('/', '') === item.path) {
        setCurrentNav(item)
        updatePageName(item.page)
        if (pages?.[item.page]) {
          updatePage(pages[item.page])
        } else {
          //add logic to call api
          // dispatch(setLoader(true))
          setLoadingPage(true)
          if (!pageRequested || (!coreData?.page[item?.page] && !pageRequestedAfterCMSRefresh)) {
            setPageRequested(true)
            if (!coreData?.page?.[item?.page]) {
              setPageRequestedAfterCMSRefresh(true)
            }

            if (item?.page) {
              request(`get-page/${item?.page}`, 'GET').then((data) => {
                let pageData = {}
                if (!data) return
                if (data.status === 200) {
                  if (data?.data?.data?.length === 0) {
                    pageData[item?.page] = {}
                  } else {
                    pageData[item?.page] = { ...data.data }
                  }

                  dispatch(updatePageDataRedux(pageData))
                } else if (data?.status === 401) {
                  history.push('/logout')
                }

                if (data?.data?.tab_near_to_pages && JSON.stringify(data?.data?.tab_near_to_pages) !== '[]') {
                  if (Object.keys(data?.data?.tab_near_to_pages)?.length > 0) {
                    Object.keys(data?.data?.tab_near_to_pages)?.map((item, index) => {
                      pageData[item] = {
                        ...data?.data?.tab_near_to_pages[item],
                        isRemovable: true,
                      }
                      if (item.name) {
                        pageData[item.name] = {
                          ...data?.data?.tab_near_to_pages[item],
                          isRemovable: true,
                        }
                      }
                    })
                  }
                }

                dispatch(updatePageDataRedux(pageData))

                // dispatch(setLoader(false))
                setLoadingPage(false)
              })
            }
          }

          // updatePage('')
        }
      }
    })
  }, [page, navMenu, cmsData, coreData])

  useEffect(() => {
    if (coreData?.domains && !domain_id) {
      const selected_domain = coreData?.domains?.find((domain) => domain.active)
      dispatch(setDomain(selected_domain?.domain_id))
    }
  }, [coreData])

  const { allowed, component_name } = page


  if (JSON.stringify(page) === '{}' && pageName) {
    return (
      <Wrapper cmsData={cmsData}>
        {!loading && !loadingPage ? (
          <CreatePageRoute pageName={pageName} isMainPageCreate={currentNav?.main_page} currentNavData={currentNav} />
        ) : (
          <div>Loading....</div>
        )}
      </Wrapper>
    )
  } else if (!allowed) {
    return <Loader />
  } else {
    return (
      <Wrapper cmsData={cmsData}>
        {page?.tab_near_to_pages ? (
          <PageWithTabsNear page={page} />
        ) : (
          <>
            {page && (component_name === 'page_without_tabs' || component_name === 'page_with_tabs') ? (
              <PageWithTabs page={page} CMSData={cmsData} disableBG={true} grid={true} />
            ) : (
              <>{page?.common_setting && <Dashboard page={page} />}</>
            )}
          </>
        )}
      </Wrapper>
    )
  }
}

export default connect(select)(withRouter(CMSPage))
