import React, { useState, useEffect } from 'react'
import { Table, Input, Pagination, PaginationItem, PaginationLink, Button } from 'reactstrap'

import Loader from 'react-loaders'
import { primary_color } from '../../../../Helper/uiHelper'
import useFetchLockerCodes from '../../../../Queries/BlockPlan/useFetchLockerCodes'
import IconSet from '../../../../CMSComponent/icon'
import AddEditForm from './addEditForm'
import { request } from '../../../../Redux/Sagas/requests/api'
import { isNumeric } from '../../../../helper'

const checkUserSelected = (users, uuid) => {
  if (users?.includes(uuid)) {
    return true
  } else {
    return false
  }
}

const CodesTable = ({ blockDetails, form, setBlocketails }) => {
  const [blockName, setBlockName] = useState('')
  const [lockerNumber, setLockerNumber] = useState('')
  const [code, setCode] = useState()
  const [note, setNote] = useState('')
  const [page, setPage] = useState()
  const [academicYear, setAcademicYear] = useState()

  const [selectedLocker, setSelectedLocker] = useState([])
  const [editData, setEditData] = useState(false)
  const [editableCell, setEditableCell] = useState({})

  const [lockers, setLockers] = useState([])
  const [lockerData, setLockerData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const handleLockerSelection = (checked, locker) => {
    if (checked) {
      const newUser = [...selectedLocker]
      newUser?.push(locker)
      setSelectedLocker(newUser)
    } else {
      const newUser = [...selectedLocker]
      let index
      newUser?.map((item, i) => {
        if (item?.id === locker?.id) {
          index = i
        }
      })
      newUser?.splice(index, 1)
      setSelectedLocker(newUser)
    }
  }
  const refetchCode = () => {
    setIsLoading(true)
    request('get-code-by-location-id', 'POST', {
      location_id: blockDetails?.id,
      page,
      item_date_time_id_label: academicYear,
      location_id_label: blockName,
      name: lockerNumber,
      code,
      note,
    }).then((res) => {
      if (res?.data?.data) {
        setLockers(res?.data?.data?.data)
        setLockerData(res?.data)
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    refetchCode()
  }, [page])

  const handleDoubleClick = (rowIndex, colIndex) => {
    setEditableCell({ row: rowIndex, col: colIndex })
  }

  const handleChange = (index, value) => {
    if ((isNumeric(value) || !value) && value?.length < 5) {
      const newLockers = [...lockers]
      newLockers[index]['code'] = value
      request('insert-codes', 'POST', { ...newLockers[index] })
      setLockers(newLockers)
    }
  }
  const selectedLockerUUID = selectedLocker?.map((item) => item?.id)

  if (editData)
    return (
      <AddEditForm
        form={form}
        defaultValue={editData}
        blockDetails={blockDetails}
        onCloseForm={() => {
          refetchCode()
          setEditData()
        }}></AddEditForm>
    )
  return (
    <div className='px-5 mt-4 merge-user-table'>
      {/* <div className='float-right'>
        <Button
          className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          // onClick={onPrintClick}
          disabled={true}>
          <IconSet icon='fa-regular fa-print' color='light' />
        </Button>
        <Button
          className={`btn-success border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          onClick={() => {
            setIsFormOpen(true)
          }}>
          <IconSet icon='fa-solid fa-plus' color='light' />
        </Button>
        <Button
          className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          // onClick={onPrintClick}
          disabled={true}>
          <IconSet icon='fa-solid fa-sync' color='light' />
        </Button>
        <Button
          className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          onClick={() => {
            setAcademicYear('')
            setNote('')
            setCode('')
            setLockerNumber('')
            setBlockName('')
          }}>
          <IconSet icon='fas fa-ban' color='light' />
        </Button>
      </div> */}
      <Table hover className='rt-table notes-type-table'>
        <thead className='rt-thead -header'>
          <tr className='rt-tr'>
            <th className='rt-th'></th>
            <th className='rt-th' style={{ border: 'none' }}></th>

            <th className='subtitle1 font-weight-bold  rt-th' style={{ color: primary_color }}>
              Block Name
            </th>
            <th className='subtitle1 font-weight-bold  rt-th' style={{ color: primary_color }}>
              Locker Number
            </th>
            <th className='subtitle1 font-weight-bold  rt-th' style={{ color: primary_color }}>
              Codes
            </th>
            <th className='subtitle1 font-weight-bold  rt-th' style={{ color: primary_color }}>
              Notes
            </th>
          </tr>
          <tr className='rt-tr'>
            <th className='rt-th'></th>
            <th className='rt-th'></th>

            <th className='rt-th'>
              <Input type='text ' className='subtitle1' placeholder='Block' value={blockName} onChange={(e) => setBlockName(e.target.value)} />
            </th>
            <th className='rt-th'>
              <Input
                type='text '
                className='subtitle1'
                placeholder='Locker Number'
                value={lockerNumber}
                onChange={(e) => setLockerNumber(e.target.value)}
              />
            </th>
            <th className='rt-th'>
              <Input type='text ' className='subtitle1' placeholder='Code' value={code} onChange={(e) => setCode(e.target.value)} />
            </th>
            <th className='rt-th '>
              <Input type='text' className='subtitle1' placeholder='Note' value={note} onChange={(e) => setNote(e.target.value)} />
            </th>
          </tr>
        </thead>
        <tbody className='rt-tr-group'>
          {isLoading ? (
            <tr className='rt-tr'>
              <td colSpan={5} className='rt-td'>
                <div className='d-flex justify-content-center' style={{ marginTop: '4rem', marginBottom: '8rem' }}>
                  <Loader type='ball-pulse' color='blue' />
                </div>
              </td>
            </tr>
          ) : (
            <>
              {lockers?.map((item, rowIndex) => {
                return (
                  <tr key={item.id} className={`${rowIndex % 2 === 0 && 'row-odd'}`}>
                    <td className='rt-td'>
                      <input
                        type='checkbox'
                        checked={checkUserSelected(selectedLockerUUID, item?.id)}
                        onChange={(e) => handleLockerSelection(e.target.checked, item)}></input>
                    </td>
                    <td className='rt-td'>
                      <Button
                        className={`btn-icon btn-icon-only btn btn-primary btn-sm`}
                        style={{ background: primary_color }}
                        onClick={() => {
                          setEditData(item)
                        }}>
                        <IconSet icon='far fa-edit' color='light' />
                      </Button>
                    </td>
                    {/* <td className='subtitle1'>{item.item_date_time_id_label}</td> */}
                    <td className='subtitle1 rt-td'>{item.location_id_label}</td>
                    <td className='subtitle1 rt-td'>{item.name}</td>
                    <td
                      className='rt-td subtitle1'
                      onClick={() => handleDoubleClick(rowIndex, 0)}
                      style={
                        {
                          // backgroundColor: editableCell.row === rowIndex && editableCell.col === 0 ? '#049FDB1A' : 'transparent',
                        }
                      }>
                      {editableCell.row === rowIndex && editableCell.col === 0 ? (
                        <input
                          type='text'
                          value={item.code}
                          onChange={(e) => handleChange(rowIndex, e.target.value)}
                          onBlur={() => setEditableCell({ row: null, col: null })}
                          autoFocus
                          style={{
                            // backgroundColor: '#049FDB1A',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                          }}
                        />
                      ) : (
                        item?.code
                      )}
                    </td>
                    <td className='subtitle1 rt-td' dangerouslySetInnerHTML={{ __html: item.note }}></td>
                  </tr>
                )
              })}
            </>
          )}
        </tbody>
      </Table>

      <div className='d-flex justify-content-center'>
        <Pagination aria-label='Page navigation example' style={{ flexWrap: 'wrap' }}>
          {lockerData?.data?.links?.map((item, key) => {
            return (
              <PaginationItem active={item.active} disabled={item.url === null} key={key} className='subtitle1'>
                <PaginationLink
                  first
                  onClick={() => {
                    if (item?.label?.toLowerCase()?.includes('previous')) {
                      setPage(parseInt(lockerData?.data?.current_page) - 1)
                    } else if (item?.label?.toLowerCase()?.includes('next')) {
                      setPage(parseInt(lockerData?.data?.current_page) + 1)
                    } else {
                      setPage(parseInt(item?.label))
                    }
                  }}
                  className='subtitle1'>
                  {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                </PaginationLink>
              </PaginationItem>
            )
          })}
        </Pagination>
      </div>
    </div>
  )
}

export default CodesTable
